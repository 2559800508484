<template>
    <div class="px-3 py-2">
        <v-form
            ref="formcc"
            lazy-validation 
        >
        <b-sidebar backdrop id="scanQR" width="700px" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right title="اضافة مهمة" shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>Scan QR Code</span>
                </div>
                <div @click="hide" id="hideAddUnit" style="text-align:center;width:100px;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
            </div>
            <v-divider></v-divider>
            <v-row class="m-2" style="direction:rtl">
                <v-col cols="12" md="12">
                    <iframe style="width:100%;padding:5px;border:none;height:500px;" :src="`https://solo.wablas.com/api/device/scan?token=${api_token}`" />
                </v-col>
                
            </v-row>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addUnit()" variant="success" class="ma-2" style="width:100px;">{{ lang.save }}</b-button>
                </div>
            </template>
        </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            api_token: '',
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        
    },
    methods: {
        
    },
    created() {
        // this.getUnits();
    },
}
</script>